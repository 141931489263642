@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "BigShouldersDisplayBold";
  src: url("./assets/font/Big\ Shoulders\ Display\ Font/big-shoulders-display.bold.ttf");
}
@font-face {
  font-family: "BigShouldersDisplayMedium";
  src: url("./assets/font/Big\ Shoulders\ Display\ Font/big-shoulders-display.medium.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Jura:wght@300;400;500;600;700&display=swap");
.BigShouldersDisplayMedium {
  font-family: "BigShouldersDisplayMedium";
}

ul,
li {
  list-style: none;
}

.BigShouldersDisplayBold {
  font-family: "BigShouldersDisplayBold";
}

.jura {
  font-family: "Jura", sans-serif;
}
.glass {
  background: rgba(25, 25, 25, 0.32);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.8px);
  -webkit-backdrop-filter: blur(8.8px);
  border: 1px solid rgba(25, 25, 25, 0.32);
}
body {
  font-family: "Inter", sans-serif;
}

.scrolling-function-horizontal {
  animation-name: horizontalLine;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.scrolling-function-up {
  animation-name: horizontalUp;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.scrolling-function-down {
  animation-name: horizontalDown;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.scrollrotate {
  animation-name: scrollrotate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.DigitalCard:hover img,
.JoinCard:hover img {
  transform: scale(1.1);
}
a.bg-\[\#FF00CC\],
a.bg-\[\#fff\],
a.bg-\[\#742866\],
a.bg-\[\#1C1326\] {
  transition: 0.3s ease all;
}
a.bg-\[\#FF00CC\]:hover {
  background-color: #c3059d;
}
a.bg-\[\#fff\]:hover {
  transform: scale(1.05);
}
a.bg-\[\#742866\]:hover,
a.bg-\[\#1C1326\]:hover {
  background-color: #ff00cc;
}
.zoomeffect {
  animation: zoomeffect 0.5s alternate infinite;
}

@keyframes zoomeffect {
  0% {
    transform: scale(1) rotate(15deg);
  }

  100% {
    transform: scale(1.2) rotate(15deg);
  }
}
